<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Marine Insurance List')"></tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />
      <tw-entity-buttons />

      <el-form class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Trading ID / Flow ID')" prop="tradingFlowId">
            <el-input type="text" v-model.trim="searchForm.tradingFlowId" placeholder="Enter keyword" class="trading-flow"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
            <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
            <el-input type="text" v-model="searchForm.flowName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Process')" prop="processTypes">
            <el-select v-model="searchForm.processTypes" :class="{multiple_selected: multipleSelect(searchForm.processTypes)}" placeholder="Select" multiple collapse-tags clearable>
              <el-option
                v-for="process in processes"
                :key="process.name"
                :label="process.name"
                :value="process.processType"
              >
                <span>{{process.name}}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Process User Memo')" prop="processUserMemo">
            <el-input type="text" v-model="searchForm.processUserMemo" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Invoice No')" prop="invoiceNo">
                <el-input type="text" v-model="searchForm.exCustomInvoiceNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.O/P No')" prop="opNo">
                <el-input type="text" v-model="searchForm.insuranceOpenPolicyNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.I/P No')" prop="ipNo">
                <el-input type="text" v-model="searchForm.insurancePolicyNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Branch No')" prop="no">
                <el-input type="text" v-model="searchForm.insurancePolicyBranchNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
                <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
              </el-form-item>
              <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
                <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item :label="$t('List.Application No')" prop="ipApplicationNo">
                <el-input type="text" v-model="searchForm.ipApplicationNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('List.Registration No')" prop="registrationNo">
                <el-input type="text" v-model="searchForm.registrationNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('List.Form No')" prop="formNoPattern">
                <el-input type="text" v-model="searchForm.formNoPattern" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('List.IPREQ Status')" prop="ipreqStatus">
                <el-select v-model="searchForm.ipreqStatus" placeholder="Select" clearable>
                  <el-option
                    v-for="status in IP_REQUEST_STATUS_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('List.Request Type')" prop="requestType">
                <el-select v-model="searchForm.requestType" placeholder="Select" clearable>
                  <el-option
                    v-for="status in IP_REQUEST_TYPE_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item :label="$t('Label.Vessel Name')" prop="vesselName">
                <el-input type="text" v-model="searchForm.nameOfVessel" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <!-- <el-form-item :label="$t('Label.Application date')" prop="applicationDate">
                <el-date-picker
                  v-model="searchForm.applicationDate"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item> -->
              <el-form-item :label="$t('Label.Sailing Date')" prop="loadingPortEtd">
                <el-date-picker
                  v-model="searchForm.loadingPortEtd"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Label.Assured')" prop="assuredsEtc">
                <el-input type="text" v-model="searchForm.assuredsEtc" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('List.Request Datetime')" prop="requestDatetime">
                <el-date-picker
                  v-model="searchForm.requestDatetime"
                  type="datetimerange"
                  range-separator="to"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  :default-time="['00:00:00', '23:59:59']"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item :label="$t('Label.Port of Loading')" prop="portOfLoadingName">
                <el-input type="text" v-model="searchForm.portOfLoadingName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Port of Discharge')" prop="portOfDischargingName">
                <el-input type="text" v-model="searchForm.portOfDischargingName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Insurance Company')" prop="insuranceCompanyName">
                <el-input type="text" v-model="searchForm.insuranceCompanyName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Main Goods')" prop="goodsName">
                <el-input type="text" v-model="searchForm.contractGroupOfGoods" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Status')" prop="status" style="margin-right: 40px">
                <el-select v-model="searchForm.status" :class="{multiple_selected: multipleSelect(searchForm.status)}" placeholder="Select" multiple collapse-tags clearable>
                  <el-option
                    v-for="status in PROCESS_PROGRESS_STATUS_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>

          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system :schemas="schemas" :items="items" :pageSize="pageSize" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { MARINE_INSURANCE_SEARCH_SORT_TARGET, DEFAULT_PAGENATION, ENTITY_TYPE, IP_REQUEST_STATUS_VARIABLES, IP_REQUEST_TYPE_VARIABLES } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinEntity from '@/utils/mixinEntity.js';
import { collapseTransition } from '@/utils/nextFrame';
import { clearSearchForm, formatUtcDate } from '@/utils/searchUtil.js';
import schemas from '@/dictionaries/listInsuranceSchema.json';

const ENTITY_NAME = 'MARINE_IP';

export default {
  name: 'ListInsurance',
  mixins: [mixinEntityList, collapseTransition, mixinEntity],
  data() {
    return {
      entityCode: ENTITY_TYPE.MARINE_IP,
      schemas: schemas,
      items: null,
      searchForm: {
        tradingFlowId: '',
        tradingName: '',
        flowName: '',
        exCustomInvoiceNo: '',
        insuranceOpenPolicyNo: '',
        insurancePolicyNo: '',
        insurancePolicyBranchNo: '',
        fromSectionIds: [],
        toSectionIds: [],
        nameOfVessel: '',
        // applicationDate: '',
        loadingPortEtd: '',
        assuredsEtc: '',
        portOfLoadingName: '',
        portOfDischargingName: '',
        insuranceCompanyName: '',
        contractGroupOfGoods: '',
        status: [],
        // ↓↓ Ph7追加 ↓↓
        ipApplicationNo: '',
        registrationNo: '',
        requestDatetime: '',
        formNoPattern: '',
        ipreqStatus: '',
        requestType: '',
        processTypes: [],
        processUserMemo: ''
      },
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
      IP_REQUEST_STATUS_VARIABLES: IP_REQUEST_STATUS_VARIABLES,
      IP_REQUEST_TYPE_VARIABLES: IP_REQUEST_TYPE_VARIABLES,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Marine Insurance List')},
      ];
    },
  },
  created() {
    const queries = this.$store.getters.getQueries(ENTITY_NAME);
    if (queries) {
      this.searchForm = queries;
    }
    this.fetch();
    this.processes = this.getProcessesByEntity(ENTITY_NAME);
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        fromSectionIds: f.fromSectionIds,
        toSectionIds: f.toSectionIds,
        sailingStartDate: this.getYMDStartDate(f.loadingPortEtd),
        sailingEndDate: this.getYMDEndDate(f.loadingPortEtd),
        tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
        tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
        tradingName: f.tradingName,
        flowName: f.flowName,
        invoiceNo: f.exCustomInvoiceNo,
        opNo: f.insuranceOpenPolicyNo,
        ipNo: f.insurancePolicyNo,
        no: f.insurancePolicyBranchNo,
        vesselName: f.nameOfVessel,
        portOfLoadingName: f.portOfLoadingName,
        portOfDischargingName: f.portOfDischargingName,
        goodsName: f.contractGroupOfGoods,
        processProgressStatus: this.getProcessProgressStatus(f.status),
        assuredsEtc: f.assuredsEtc,
        insuranceCompanyName: f.insuranceCompanyName,
        // ↓↓ Ph7追加 ↓↓
        ipApplicationNo: f.ipApplicationNo,
        registrationNo: f.registrationNo,
        requestDatetimeFrom: _.get(f, 'requestDatetime[0]') ? formatUtcDate(f.requestDatetime[0]) : null,
        requestDatetimeTo: _.get(f, 'requestDatetime[1]') ? formatUtcDate(f.requestDatetime[1]).replace('00Z', '59Z') : null,
        formNoPattern: f.formNoPattern,
        ipreqStatus: f.ipreqStatus,
        requestType: f.requestType,
        processIds: f.processTypes ? _.flatten(_.map(f.processTypes, item => this.getProcessIdsByProcessType(item))) : [],
        processUserMemo: f.processUserMemo,
      };

      return {
        ...queries,
        initialFlag: this.initialFlag(queries),
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      // bff_mi_1 海上保険一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/marine-insurances/search',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api.request(params)
      .then(res => {
        this.items = res.insurances;
        this.totalCount = res.totalCount;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        this.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      this.offset = 0;
      this.$store.commit('SET_QUERIES', {key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', {key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm)});
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [MARINE_INSURANCE_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // bff_mi_1 海上保険一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/marine-insurances/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined }
          }
        };

        $api.request(params)
        .then(res => {
          resolve(res.insurances);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
